<template>
    <div class="form">
        <el-form :model="searchForm">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="设备序列号:">
                        <el-input v-model="searchForm.serial_number" clearable></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="6">
                    <el-form-item label="设备型号:">
                        <el-cascader v-model="searchForm.device_type_id" :options="deviceCascader"
                            :props="{value:'id',label:'name',children: 'types'}" clearable />
                    </el-form-item>
                </el-col> -->
                <el-col :span="6">
                    <el-button type="primary" @click="search">
                        <el-icon style="color:#fff">
                            <Search />
                        </el-icon>
                        <span style="margin-left:15px">搜索</span>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table">
        <c-table ref="deviceTable" :tableDataUrl="deviceTableDataUrl" :isLoadData="true">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column label="设备序列号">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.serial_number" placement="top-start">
                            <span class="text-overflow">{{scope.row.serial_number}}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="copyWord(scope.row.serial_number)">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="设备密钥">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.secret" placement="top-start">
                            <span class="text-overflow">{{scope.row.secret}}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="copyWord(scope.row.secret,'secret')">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="机器码">
                <template #default="scope">
                    <span v-if="scope.row.mark">
                        {{scope.row.mark}}
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="所属商户" prop="merchant.merchant_name" />
            <el-table-column label="所属门店" prop="store.name" />
            <el-table-column label="设备分类">
                <template #default="scope">
                    <el-tag>{{deviceCategories[scope.row.device_category_id].name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="设备型号">
                <template #default="scope">
                    <el-tag type="warning">
                        {{deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id]
                        ? deviceCategories[scope.row.device_category_id].device_types[scope.row.device_type_id].name
                        :deviceCategories[scope.row.device_category_id].name}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间">
                <template #default="scope">
                    {{timeFormat(scope.row.created_at)}}
                </template>
            </el-table-column>
        </c-table>
    </div>

</template>

<script setup>
import cTable from '@/components/CustomTable'
import { ref, reactive, getCurrentInstance } from 'vue'
import { timeFormat } from '@/func/time'
import { ElMessage } from 'element-plus'
import { Search, CopyDocument } from '@element-plus/icons-vue'
const { proxy } = getCurrentInstance() // 当前实例

const deviceTableDataUrl = proxy.$api.device.list
const deviceTable = ref(null)
const deviceCategories = ref([])
const deviceCascader = ref([])
const searchForm = reactive({
    serial_number: ''
})

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}

proxy.$api.merchant.device.deviceCategory().then(r => {
    let tmpeDevice = []
    r.data.forEach((element) => {
        let deviceTypes = []
        let tempDevivceTypes = []
        element.device_types.forEach(elem => {
            deviceTypes[elem.id] = elem
            tempDevivceTypes.push(elem)
        })
        element.device_types = deviceTypes
        deviceCategories.value[element.id] = element

        if (tempDevivceTypes.length > 0) {
            element.types = tempDevivceTypes
            tmpeDevice.push(element)
        }
    })

    deviceCascader.value = tmpeDevice
})

const search = () => {
    deviceTable.value.setParams(searchForm)
}
</script>

<style lang="less" scoped>
.icon-box {
    display: flex;
    flex-direction: row;

    .text-overflow {
        width: 50%;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .copy {
        color: #409EFF !important;
        cursor: pointer;
    }

    .copyed {
        color: #52c41a !important;
    }

    .icon {
        font-size: 14px !important;

    }
}
</style>